<template>
  <component :is="component" :result="formattedResult" v-on:open-profile="onItemClick"/>
</template>
<script>
import TeamResultRow from "./TeamResultRow.vue";
import TeamResultCard from "./TeamResultCard.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: ["result", "screen"],
  computed: {
    ...mapGetters(["userData", "specificLinks"]),
    isEven() {
      return this.result.rank % 2 == 0;
    },
    formattedResult() {
      return {
        isEven: this.isEven,
        time: this.time,
        isOwner: this.result.role === "owner",
        isCaptain: this.result.role === "captain",
        lastName: this.result.last_name?.charAt(0) || "",
        avatarLetters:
          (this.result.first_name?.charAt(0) || "") +
          (this.result.last_name?.charAt(0) || ""),
        isCurrentUser: this.userData.id === this.result?.id,
        ...this.result,
      };
    },
    time() {
      let time = this.result.stats.completion_time_secs;
      if (!time) return "--";
      // let msIndex = time.indexOf(".");
      // if (msIndex !== -1) time = time.substring(0, time.indexOf("."));
      // console.log(time, "TIME");
      return moment().startOf("day").seconds(time).format("H:mm:ss");
    },
    component() {
      if (this.screen <= 899) return TeamResultCard;
      return TeamResultRow;
    },
  },
  methods: {
    onItemClick() {
      let windowReference = window.open();
      windowReference.location = `${this.specificLinks.authPage}/race/profile/${this.result.id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>