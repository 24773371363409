<template>
  <div
    class="result-card"
    :class="{
      'second-bg': result.isEven,
      'current-user': result.isCurrentUser,
    }"
  >
    <div class="member-position">{{ result.rank }}</div>
    <div class="card-body">
      <div class="member-info" @click="openPublicProfile">
        <div
          class="member-avatar"
          :class="{ 'spartan-plus': result.spartan_plus }"
        >
          <img v-if="result.avatar" :src="result.avatar" alt="" />
          <default-avatar v-if="!result.avatar" :size="30" :font-size="12">{{
            result.avatarLetters
          }}</default-avatar>
          <div class="is-captain" v-if="result.isCaptain || result.isOwner">
            <i class="material-icons" v-if="result.isCaptain"> star_rate </i>
            <v-icon v-if="result.isOwner">mdi-crown</v-icon>
          </div>
        </div>
        <div class="member-name">
          <span>{{ result.first_name }} {{ result.lastName }}.</span>
          <span class="spartan-plus-subline" v-if="result.spartan_plus"
            >spartan+</span
          >
        </div>
      </div>
      <div class="member-results">
        <div class="result-item">
          <div class="result-title">{{ $t("category") }}</div>
          <div class="result-value">
            <span>{{ result.stats.rank_overall || "--" }}</span>
            <span>/{{ result.stats.count_overall || "--" }}</span>
          </div>
        </div>
        <div class="result-item">
          <div class="result-title">{{ $t("by_gender") }}</div>
          <div class="result-value">
            <span>{{ result.rank_gender || "--" }}</span>
            <span>/{{ result.count_gender || "--" }}</span>
          </div>
        </div>
        <div class="result-item">
          <div class="result-title">{{ $t("time") }}</div>
          <div class="result-value">{{ result.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["result"],
  components: {
    "default-avatar": () => import("@/components/DefaultAvatar.vue"),
  },
  methods: {
    openPublicProfile() {
      this.$emit("open-profile");
    },
  },
};
</script>
<style lang="scss" scoped>
.result-card {
  display: grid;
  grid-template-columns: 55px 1fr;
  min-height: 122px;
  padding-top: 21px;
  padding-bottom: 15px;
  background-color: #121212;
  font-size: 14px;
  line-height: 17px;
  .member-position {
    text-align: center;
    font-weight: bold;
    padding-top: 3px;
  }
  .card-body {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 23px;
    .member-info {
      display: flex;
      flex-direction: row;
      .member-avatar {
        margin-right: 14px;
        position: relative;
        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
        }
        .is-captain {
          position: absolute;
          top: 0;
          right: -2px;
          z-index: 10;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: #cf1019;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          i {
            font-size: 8px;
          }
        }
      }
      .spartan-plus {
        position: relative;
      }
      .spartan-plus::after {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        border-radius: 50%;
        border: 2px solid #cf1019;
        height: 30px;
        width: 30px;
      }
      .member-name {
        display: flex;
        flex-direction: column;
        text-transform: capitalize;
        font-weight: bold;
        padding-top: 3px;
        .spartan-plus-subline {
          color: #cf1019;
          font-size: 9px;
          line-height: 11px;
          font-weight: 600;
          margin-top: 2px;
        }
      }
    }
    .member-results {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // grid-template-columns: repeat(3, minmax(auto, max-content));
      column-gap: 33px;
      .result-item {
        .result-title {
          font-size: 10px;
          line-height: 12px;
          font-weight: 600;
          color: #9d9d9d;
          margin-bottom: 4px;
          text-transform: uppercase;
        }
        .result-value {
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
        }
      }
    }
  }
}
.second-bg {
  background-color: #000000;
}
.current-user {
  background-color: #cf102d;
  .card-body {
    .member-info {
      .member-avatar {
        .is-captain {
          background-color: #ffffff;
          i {
            color: #cf102d;
          }
        }
      }
      .member-name {
        .spartan-plus-subline {
          color: #ffffff;
        }
      }
      .spartan-plus::after {
        border: 2px solid #ffffff;
      }
    }
    .member-results {
      .result-item {
        .result-title {
          color: #ffffff;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>